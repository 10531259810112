import { LazyExoticComponent, lazy } from "react";
import { Constants } from "utils/Constants";

const TeachersPage = lazy(() => import("pages/teachers/TeachersPage"));
const HomePage = lazy(() => import("pages/HomePage"));
const CreateGamePage = lazy(() => import("pages/games/GamesNewGamePage"));
const EnterCodePage = lazy(() => import("pages/games/GamesEnterCodePage"));
const ModerateGamePage = lazy(() => import("pages/games/GamesModerateGamePage"));
const TeachersGroupsPage = lazy(() => import("pages/teachers/TeachersGroupsPage"));
const GamesGamePage = lazy(() => import("pages/games/GamesGamePage"));
const GamesPage = lazy(() => import("pages/games/GamesPage"));
const GameSettingsPage = lazy(() => import("pages/games/GamesSettingsPage"));
const GamesJoinPage = lazy(() => import("pages/games/GamesJoinPage"));
const PromptsPage = lazy(() => import("pages/prompts/PromptsPage"));
const PromptsBrowsePage = lazy(() => import("pages/prompts/PromptsBrowsePage"));
const PromptsTopicPage = lazy(() => import("pages/prompts/PromptsTopicPage"));
const PromptsSearchPage = lazy(() => import("pages/prompts/PromptsSearchPage"));
const GamesSpectatePage = lazy(() => import("pages/games/GamesSpectatePage"));
const StoryPage = lazy(() => import("pages/games/GamesStoryPage"));
const GamesStoryRedirectPage = lazy(() => import("pages/games/GamesStoryRedirectPage"));
const RandomPage = lazy(() => import("pages/RandomPage"));
const MyGamesPage = lazy(() => import("pages/games/GamesMyGamesPage"));
const GamesMultiModeratePage = lazy(() => import("pages/games/GamesMultiModeratePage"));
const HallOfFamePage = lazy(() => import("pages/games/GamesHallOfFamePage"));
const TermsPage = lazy(() => import("pages/TermsPage"));
const PrivacyPage = lazy(() => import("pages/PrivacyPage"));
const EventsPage = lazy(() => import("pages/help/HelpEventsPage"));
const FriendsPage = lazy(() => import("pages/FriendsPage"));
const SubscriptionsAboutPage = lazy(() => import("pages/subscriptions/SubscriptionsAboutPage"));
const SafetyPage = lazy(() => import("pages/SafetyPage"));
const PermissionsPage = lazy(() => import("pages/PermissionsPage"));
const CompetitionTermsPage = lazy(() => import("pages/CompetitionTermsPage"));
const StudentWritingResourcesPage = lazy(() => import("pages/StudentWritingResourcesPage"));
const HelpTeamPage = lazy(() => import("pages/help/HelpTeamPage"));
const LoginPage = lazy(() => import("pages/account/LoginPage"));
const ApproveUserPage = lazy(() => import("pages/account/ApproveUserPage"));
const ChangePasswordPage = lazy(() => import("pages/account/ChangePasswordPage"));
const RecoverPasswordPage = lazy(() => import("pages/account/RecoverPasswordPage"));
const TeachersGroupPage = lazy(() => import("pages/teachers/TeachersGroupPage"));
const TeachersStudentPage = lazy(() => import("pages/teachers/TeachersStudentPage"));
const TeachersStudentsPage = lazy(() => import("pages/teachers/TeachersStudentsPage"));
const TeachersCreateGroupPage = lazy(() => import("pages/teachers/TeachersCreateGroupPage"));
const TeachersAddStudentsPage = lazy(() => import("pages/teachers/TeachersAddStudentsPage"));
const RegisterPage = lazy(() => import("pages/account/RegisterPage"));
const StudentLoginPage = lazy(() => import("pages/account/StudentLoginPage"));
const ProfilePage = lazy(() => import("pages/profile/ProfilePage"));
const ProfileDetailsPage = lazy(() => import("pages/profile/ProfileDetailsPage"));
const ProfileMyAvatarsPage = lazy(() => import("pages/profile/ProfileMyAvatarsPage"));
const ProfileMyClassesPage = lazy(() => import("pages/profile/ProfileMyClassesPage"));
const ProfileMyClassesClassPage = lazy(() => import("pages/profile/ProfileMyClassesClassPage"));
const ProfileMyClassesClassStudentPage = lazy(() => import("pages/profile/ProfileMyClassesClassStudentPage"));
const ProfileMyClassesClassStudentRepliesPage = lazy(
    () => import("pages/profile/ProfileMyClassesClassStudentRepliesPage"),
);
const ProfileMyClassesClassGamesPage = lazy(() => import("pages/profile/ProfileMyClassesClassGamesPage"));
const ProfileJoinGroupPage = lazy(() => import("pages/profile/ProfileJoinGroupPage"));
const ProfileReferAFriendPage = lazy(() => import("pages/profile/ProfileReferAFriendPage"));
const HelpContactPage = lazy(() => import("pages/help/HelpContactPage"));
const TeachersPanelPage = lazy(() => import("pages/teachers/TeachersPanelPage"));
const TeachersGroupRemoveStudentsPage = lazy(() => import("pages/teachers/TeachersGroupRemoveStudentsPage"));
const HelpNewsletterPage = lazy(() => import("pages/help/HelpNewsletterPage"));
const EduTechPage = lazy(() => import("pages/EduTechPage"));
const TeachersStudentUpdateDetailsPage = lazy(() => import("pages/teachers/TeachersStudentUpdateDetailsPage"));
const RegisterStudentPage = lazy(() => import("pages/account/RegisterStudentPage"));
const ProfileMySchoolPage = lazy(() => import("pages/profile/ProfileMySchoolPage"));
const ProfileJoinSchoolPage = lazy(() => import("pages/profile/ProfileJoinSchoolPage"));
const ProfileLeaveSchoolPage = lazy(() => import("pages/profile/ProfileLeaveSchoolPage"));
const AcceptInvitationPage = lazy(() => import("pages/account/AcceptInvitationPage"));
const TeachersSubscriptionsPage = lazy(() => import("pages/teachers/TeachersSubscriptionsPage"));
const SubscriptionsPurchaseSuccessPage = lazy(() => import("pages/subscriptions/SubscriptionsPurchaseSuccessPage"));
const GamesTemplatePage = lazy(() => import("pages/games/GamesTemplatePage"));
const GamesTemplateAssignToGroupPage = lazy(() => import("pages/games/GamesTemplateAssignToGroupPage"));
const GamesTemplateAssignAsHomeworkPage = lazy(() => import("pages/games/GamesTemplateAssignAsHomeworkPage"));
const GamesUpdateTemplatePage = lazy(() => import("pages/games/GamesUpdateTemplatePage"));
const ProfileMySubscriptionsPage = lazy(() => import("pages/profile/ProfileMySubscriptionsPage"));
const SubscriptionsPage = lazy(() => import("pages/subscriptions/SubscriptionsPage"));
const SubscriptionsReferAFriendPage = lazy(() => import("pages/subscriptions/SubscriptionsReferAFriendPage"));
const HelpPage = lazy(() => import("pages/help/HelpPage"));
const HelpTipsPage = lazy(() => import("pages/help/HelpTipsPage"));
const HelpFaqsPage = lazy(() => import("pages/help/HelpFaqsPage"));
const HelpVideosPage = lazy(() => import("pages/help/HelpVideosPage"));
const HelpResourcesPage = lazy(() => import("pages/help/HelpResourcesPage"));
const GamesMyStoriesPage = lazy(() => import("pages/games/GamesMyStoriesPage"));
const GamesMyStoriesTemplatePage = lazy(() => import("pages/games/GamesMyStoriesTemplatePage"));
const GamesMyStoriesParticipatedPage = lazy(() => import("pages/games/GamesMyStoriesParticipatedPage"));
const GamesMyStoriesModeratedPage = lazy(() => import("pages/games/GamesMyStoriesModeratedPage"));
const GamesMyStoriesHallOfFamePage = lazy(() => import("pages/games/GamesMyStoriesHallOfFamePage"));
const GamesMyStoriesClassPage = lazy(() => import("pages/games/GamesMyStoriesClassPage"));
const LandingWritingStaminaPage = lazy(() => import("pages/landing/LandingWritingStaminaPage"));
const LandingShakespearePage = lazy(() => import("pages/landing/LandingShakespearePage"));

export const RouteList = {
    Home: "/",
    Games: "/games",
    Help: "/help",
    NewGame: "/games/new-game",
    EnterCode: "/games/enter-code",
    Teachers: "/teachers",
    Subscriptions: "/subscriptions",
    Pricing: "/pricing",
    ProfileMySubscriptions: "/profile/my-subscriptions",
    ReferAFriend: "/refer-a-friend",
    SubscriptionsPurchaseSuccess: "/subscriptions/purchase/success",
    TeachersPanel: "/teachers/panel",
    TeachersAddStudents: "/teachers/students/add",
    TeachersReportingEverything: "/api/teachers/reporting/everything.csv",
    TeachersPaymentSuccess: "/subscriptions/purchase/success",
    TeachersSubscriptions: "/subscriptions/my-subscriptions",
    TeachersClasses: "/teachers/classes",
    TeachersCreateClass: "/teachers/classes/create",
    TeachersStudents: "/teachers/students",
    ProfileMySchool: "/profile/my-school",
    ProfileJoinSchool: "/profile/join-school",
    ProfileLeaveSchool: "/profile/leave-school",
    Login: "/login",
    Prompts: "/prompts",
    PromptsBrowse: "/prompts/browse",
    PromptsSearch: "/prompts/search",
    Contact: "/help/contact-us",
    Newsletter: "/help/newsletter",
    StudentLogin: "/login/students",
    RegisterTeacher: "/register",
    RegisterStudent: "/register-student",
    ChangePassword: "/profile/change-password",
    Random: "/random-page",
    HallOfFame: "/games/hall-of-fame",
    MyStories: "/games/completed-games",
    MyStoriesParticipated: "/games/completed-games/participated",
    MyStoriesModerated: "/games/completed-games/moderated",
    MyStoriesHallOfFame: "/games/completed-games/hall-of-fame",
    MyGames: "/games/my-games",
    GamesMultiModerate: "/games/my-games/multi-moderate",
    Profile: "/profile",
    ProfileDetails: "/profile/details",
    ProfileMyAvatars: "/profile/my-avatars",
    ProfileMyClasses: "/profile/my-classes",
    ProfileReferAFriend: "/profile/refer-a-friend",
    JoinClass: "/profile/join-class",
    Terms: "/help/terms",
    Privacy: "/help/privacy",
    Events: "/help/events",
    Permissions: "/help/permissions",
    CompetitionTerms: "/comp-terms",
    StudentWritingResources: "/student-writing-resources",
    HelpTeam: "/help/about-us",
    Safety: "/help/safety",
    Friends: "/friends",
    EduTech: "/edutech",
    HelpTips: "/help/tips",
    HelpFaqs: "/help/faqs",
    HelpVideos: "/help/videos",
    HelpResources: "/help/resources",
    LandingWritingStamina: "/writing-stamina",
    LandingShakespeare: "/shakespeare",
    Writelike: Constants.webUrl,
};

interface IRouteTableItem {
    path: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: LazyExoticComponent<any>;
}

export const RouteTable: Array<IRouteTableItem> = [
    {
        path: "/story/:code([A-Za-z0-9]{6})",
        Component: GamesStoryRedirectPage,
    },
    {
        path: "/recover-password/:hash",
        Component: RecoverPasswordPage,
    },
    {
        path: "/approve-user/:hash",
        Component: ApproveUserPage,
    },
    {
        path: "/accept-invitation/:hash",
        Component: AcceptInvitationPage,
    },
    {
        path: "/games/completed-games/class/:id([0-9]+)",
        Component: GamesMyStoriesClassPage,
    },
    {
        path: "/games/completed-games/game/:id([0-9]+)",
        Component: GamesMyStoriesTemplatePage,
    },
    {
        path: "/games/my-games/edit-template/:id([0-9]+)",
        Component: GamesUpdateTemplatePage,
    },
    {
        path: "/games/my-games/moderate/:code([A-Za-z0-9]{6})",
        Component: ModerateGamePage,
    },
    {
        path: "/games/my-games/template/:id([0-9]+)/assign-to-class",
        Component: GamesTemplateAssignToGroupPage,
    },

    {
        path: "/games/my-games/template/:id([0-9]+)/assign-to-individuals",
        Component: GamesTemplateAssignAsHomeworkPage,
    },
    {
        path: "/games/my-games/template/:id([0-9]+)",
        Component: GamesTemplatePage,
    },
    {
        path: "/teachers/classes/:id([0-9]+)/remove-students",
        Component: TeachersGroupRemoveStudentsPage,
    },
    {
        path: "/teachers/classes/:id([0-9]+)",
        Component: TeachersGroupPage,
    },
    {
        path: "/teachers/students/:id([0-9]+)/update-details",
        Component: TeachersStudentUpdateDetailsPage,
    },
    {
        path: "/teachers/students/:id([0-9]+)",
        Component: TeachersStudentPage,
    },
    {
        path: "/games/hall-of-fame/:code([A-Za-z0-9]{6})",
        Component: StoryPage,
    },
    {
        path: "/games/completed-games/:code([A-Za-z0-9]{6})",
        Component: StoryPage,
    },
    {
        path: "/games/spectate/:code([A-Za-z0-9]{6})",
        Component: GamesSpectatePage,
    },
    {
        path: "/games/my-games/settings/:code([A-Za-z0-9]{6})",
        Component: GameSettingsPage,
    },
    {
        path: "/join/:code([A-Za-z0-9]{6})",
        Component: GamesJoinPage,
    },
    {
        path: "/games/my-games/:code([A-Za-z0-9]{6})",
        Component: GamesGamePage,
    },
    {
        path: "/prompts/browse/:friendlyUrl/:prompt",
        Component: PromptsTopicPage,
    },
    {
        path: "/prompts/browse/:friendlyUrl",
        Component: PromptsTopicPage,
    },
    {
        path: "/profile/my-classes/:id([0-9]+)",
        Component: ProfileMyClassesClassPage,
    },
    {
        path: "/profile/my-classes/:classId([0-9]+)/games",
        Component: ProfileMyClassesClassGamesPage,
    },
    {
        path: "/profile/my-classes/:classId([0-9]+)/:studentId([0-9]+)",
        Component: ProfileMyClassesClassStudentPage,
    },
    {
        path: "/profile/my-classes/:classId([0-9]+)/:studentId([0-9]+)/replies",
        Component: ProfileMyClassesClassStudentRepliesPage,
    },
    {
        path: RouteList.ProfileReferAFriend,
        Component: ProfileReferAFriendPage,
    },
    {
        path: RouteList.Subscriptions,
        Component: SubscriptionsPage,
    },
    {
        path: RouteList.Help,
        Component: HelpPage,
    },
    {
        path: RouteList.HelpTips,
        Component: HelpTipsPage,
    },
    {
        path: RouteList.HelpResources,
        Component: HelpResourcesPage,
    },
    {
        path: RouteList.HelpFaqs,
        Component: HelpFaqsPage,
    },
    {
        path: RouteList.HelpVideos,
        Component: HelpVideosPage,
    },
    {
        path: RouteList.ReferAFriend,
        Component: SubscriptionsReferAFriendPage,
    },
    {
        path: RouteList.SubscriptionsPurchaseSuccess,
        Component: SubscriptionsPurchaseSuccessPage,
    },
    {
        path: RouteList.ProfileMySubscriptions,
        Component: ProfileMySubscriptionsPage,
    },
    {
        path: RouteList.PromptsSearch,
        Component: PromptsSearchPage,
    },
    {
        path: RouteList.Contact,
        Component: HelpContactPage,
    },
    {
        path: RouteList.Newsletter,
        Component: HelpNewsletterPage,
    },
    {
        path: RouteList.EnterCode,
        Component: EnterCodePage,
    },
    {
        path: RouteList.Teachers,
        Component: TeachersPage,
    },
    {
        path: RouteList.Home,
        Component: HomePage,
    },
    {
        path: RouteList.NewGame,
        Component: CreateGamePage,
    },
    {
        path: RouteList.Prompts,
        Component: PromptsPage,
    },
    {
        path: RouteList.PromptsBrowse,
        Component: PromptsBrowsePage,
    },
    {
        path: RouteList.TeachersPanel,
        Component: TeachersPanelPage,
    },
    {
        path: RouteList.TeachersAddStudents,
        Component: TeachersAddStudentsPage,
    },
    {
        path: RouteList.TeachersClasses,
        Component: TeachersGroupsPage,
    },
    {
        path: RouteList.TeachersCreateClass,
        Component: TeachersCreateGroupPage,
    },
    {
        path: RouteList.TeachersStudents,
        Component: TeachersStudentsPage,
    },
    {
        path: RouteList.ProfileJoinSchool,
        Component: ProfileJoinSchoolPage,
    },
    {
        path: RouteList.ProfileMySchool,
        Component: ProfileMySchoolPage,
    },
    {
        path: RouteList.ProfileLeaveSchool,
        Component: ProfileLeaveSchoolPage,
    },
    {
        path: RouteList.TeachersSubscriptions,
        Component: TeachersSubscriptionsPage,
    },
    {
        path: RouteList.Pricing,
        Component: SubscriptionsAboutPage,
    },
    {
        path: RouteList.MyGames,
        Component: MyGamesPage,
    },
    {
        path: RouteList.GamesMultiModerate,
        Component: GamesMultiModeratePage,
    },
    {
        path: RouteList.HallOfFame,
        Component: HallOfFamePage,
    },
    {
        path: RouteList.MyStories,
        Component: GamesMyStoriesPage,
    },
    {
        path: RouteList.MyStoriesParticipated,
        Component: GamesMyStoriesParticipatedPage,
    },
    {
        path: RouteList.MyStoriesModerated,
        Component: GamesMyStoriesModeratedPage,
    },
    {
        path: RouteList.MyStoriesHallOfFame,
        Component: GamesMyStoriesHallOfFamePage,
    },
    {
        path: RouteList.Random,
        Component: RandomPage,
    },
    {
        path: RouteList.Terms,
        Component: TermsPage,
    },
    {
        path: RouteList.Privacy,
        Component: PrivacyPage,
    },
    {
        path: RouteList.Events,
        Component: EventsPage,
    },
    {
        path: RouteList.Permissions,
        Component: PermissionsPage,
    },
    {
        path: RouteList.CompetitionTerms,
        Component: CompetitionTermsPage,
    },
    {
        path: RouteList.StudentWritingResources,
        Component: StudentWritingResourcesPage,
    },
    {
        path: RouteList.HelpTeam,
        Component: HelpTeamPage,
    },
    {
        path: RouteList.Safety,
        Component: SafetyPage,
    },
    {
        path: RouteList.Friends,
        Component: FriendsPage,
    },
    {
        path: RouteList.Login,
        Component: LoginPage,
    },
    {
        path: RouteList.RegisterStudent,
        Component: RegisterStudentPage,
    },
    {
        path: RouteList.RegisterTeacher,
        Component: RegisterPage,
    },
    {
        path: RouteList.StudentLogin,
        Component: StudentLoginPage,
    },
    {
        path: RouteList.ChangePassword,
        Component: ChangePasswordPage,
    },
    {
        path: RouteList.ProfileMyClasses,
        Component: ProfileMyClassesPage,
    },
    {
        path: RouteList.ProfileDetails,
        Component: ProfileDetailsPage,
    },
    {
        path: RouteList.ProfileMyAvatars,
        Component: ProfileMyAvatarsPage,
    },
    {
        path: RouteList.Profile,
        Component: ProfilePage,
    },
    {
        path: RouteList.JoinClass,
        Component: ProfileJoinGroupPage,
    },
    {
        path: RouteList.EduTech,
        Component: EduTechPage,
    },
    {
        path: RouteList.Games,
        Component: GamesPage,
    },
    {
        path: RouteList.LandingWritingStamina,
        Component: LandingWritingStaminaPage,
    },
    {
        path: RouteList.LandingShakespeare,
        Component: LandingShakespearePage,
    },
];

export const Redirects: Map<string, string> = new Map([
    ["/aate", "https://docs.google.com/forms/d/e/1FAIpQLSclNPUFoMLBEG8NECqKS1fMVunpD0aKkZ69ZfPVczEzaKOWVg/viewform"],
    [
        "/comp-entry",
        "https://docs.google.com/forms/d/e/1FAIpQLSfoId0y3JXtp6rbCAOlRdKKUomFa8sjyJmbv25ZG1XVtjC-xw/viewform?usp=sf_link",
    ],
    ["/subscriptions/about", RouteList.Pricing],
    ["/subscriptions/refer-a-friend", RouteList.ReferAFriend],
    ["/subscriptions/purchase", RouteList.Pricing],
    ["/about", RouteList.Pricing],
]);

export const RouteLink = {
    game: (c: string) => `/games/my-games/${c}`,
    settings: (c: string) => `/games/my-games/settings/${c}`,
    editTemplate: (id: number) => `/games/my-games/edit-template/${id}`,
    hallOfFameStory: (c: string) => `/games/hall-of-fame/${c}`,
    myStoriesStory: (c: string) => `/games/completed-games/${c}`,
    spectate: (c: string) => `/games/spectate/${c}`,
    join: (c: string) => `/join/${c}`,
    teacherClassRemoveStudents: (id: number) => `/teachers/classes/${id}/remove-students`,
    teacherClass: (id: number) => `/teachers/classes/${id}`,
    teacherStudent: (id: number) => `/teachers/students/${id}`,
    teacherSubscriptionAssign: (id: number) => `/subscriptions/my-subscriptions/${id}/assign`,
    teacherStudentUpdateDetails: (id: number) => `/teachers/students/${id}/update-details`,
    teachersAddStudents: (id: number) => `${RouteList.TeachersAddStudents}?id=${id}`,
    teacherReportUser: (id: number) => `/api/teachers/reporting/user/${id}.csv`,
    teacherReportGame: (id: number) => `/api/teachers/reporting/game/${id}.csv`,
    teacherReportClass: (id: number) => `/api/teachers/reporting/group/${id}.csv`,
    approveUser: (hash: string) => `/approve-user/${hash}`,
    acceptInvitation: (hash: string) => `/accept-invitation/${hash}`,
    recoverPassword: (hash: string) => `/recover-password/${hash}`,
    promptsTopic: (friendlyUrl: string, prompt?: string) =>
        `/prompts/browse/${friendlyUrl}${prompt ? `/${prompt}` : ""}`,
    gamesTemplateAssignToGroup: (id: number) => `/games/my-games/template/${id}/assign-to-class`,
    gamesTemplateAssignAsHomework: (id: number) => `/games/my-games/template/${id}/assign-to-individuals`,
    gamesTemplate: (id: number) => `/games/my-games/template/${id}`,
    myStoriesClass: (id: number) => `/games/completed-games/class/${id}`,
    myStoriesTemplate: (id: number) => `/games/completed-games/game/${id}`,
    moderate: (c: string, userId?: number) => `/games/my-games/moderate/${c}${userId ? `?u=${userId}` : ""}`,
    profileMyClassesClass: (classId: number) => `/profile/my-classes/${classId}`,
    profileMyClassesClassGames: (classId: number) => `/profile/my-classes/${classId}/games`,
    profileMyClassesClassStudent: (classId: number, studentId: number) => `/profile/my-classes/${classId}/${studentId}`,
    profileMyClassesClassStudentReplies: (classId: number, studentId: number) =>
        `/profile/my-classes/${classId}/${studentId}/replies`,
};
