import { Link } from "react-router-dom";
import { RouteList } from "Routes";

const FooterLink = (props: { name: string; url: string }) => {
    const { name, url } = props;

    return (
        <li>
            <Link className="footer__link link-white" to={url}>
                {name}
            </Link>
        </li>
    );
};

export const Footer = () => (
    <footer className="footer pb-3 d-print-none">
        <div className="container">
            <div className="vstack gap-4">
                <ul className="list-unstyled footer__list">
                    <FooterLink url={RouteList.HelpTeam} name="About us" />
                    <FooterLink url={RouteList.Terms} name="Terms of use" />
                    <FooterLink url={RouteList.Privacy} name="Privacy" />
                    <FooterLink url={RouteList.Permissions} name="Permissions" />
                    <FooterLink url={RouteList.Safety} name="Safety" />
                    <FooterLink url={RouteList.Friends} name="Friends" />
                    <FooterLink url={RouteList.ReferAFriend} name="Refer a friend" />
                </ul>

                <div className="small text-center container">
                    We acknowledge the Traditional Owners of country throughout Australia and recognise their continuing
                    connection to land, waters and culture. We pay our respects to their Elders past and present.
                </div>
            </div>
        </div>
    </footer>
);
