import { RouteList } from "Routes";
import clsx from "clsx";
import { ConditionalTooltip } from "components/ConditionalTooltip";
import { Link } from "react-router-dom";

export const ProBadge = (props: { disabled?: boolean }) => {
    const { disabled } = props;

    return (
        <ConditionalTooltip show={!disabled} text="Available in Frankenstories Pro - Register to try for free">
            <Link
                target="_blank"
                to={RouteList.Pricing}
                className={clsx(
                    "btn btn-sm btn-success rounded-pill btn-icon text-white text-uppercase",
                    disabled && "disabled opacity-100",
                )}
            >
                <span>🥇</span>
                <span>Pro</span>
            </Link>
        </ConditionalTooltip>
    );
};
