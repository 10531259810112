import { Tooltip } from "bootstrap";
import { ComponentOptions } from "bootstrap/js/dist/base-component";
import { ReactNode, useEffect, useRef } from "react";

export const ConditionalTooltip = (props: {
    children: ReactNode;
    text: string;
    show: boolean;
    placement?: "top" | "right" | "bottom" | "left";
    forceShow?: boolean;
}) => {
    const { children, text, show, placement = "bottom", forceShow } = props;

    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (divRef.current) {
            const options: ComponentOptions = {
                title: text,
                placement,
                trigger: forceShow ? "manual" : "hover",
            };

            const tooltip = Tooltip.getOrCreateInstance(divRef.current, options);

            if (show) {
                if (forceShow) {
                    tooltip.show();
                } else {
                    tooltip.enable();
                }
            } else {
                if (forceShow) {
                    tooltip.hide();
                } else {
                    tooltip.disable();
                }
            }

            return () => {
                tooltip.hide();
                tooltip.dispose();
            };
        }
    }, [placement, show, text, forceShow]);

    return <div ref={divRef}>{children}</div>;
};
