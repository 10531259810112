import { CodeCommand } from "commands/CodeCommand";
import { CodeResult } from "results/CodeResult";
import { IdResult } from "results/IdResult";
import { GetProfileMyAvatarsResult } from "results/users/GetProfileMyAvatarsResult";
import { GetProfileMySchoolResult } from "results/users/GetProfileMySchoolResult";
import { GetProfileReferAFriendResult } from "results/users/GetProfileReferAFriendResult";
import { GetUnlockedAvatarsResult } from "results/users/GetUnlockedAvatarsResult";
import { ProfileResult } from "results/users/ProfileResult";
import { GetMyClassesClassGamesResult } from "results/users/my-classes/GetMyClassesClassGamesResult";
import { GetMyClassesClassResult } from "results/users/my-classes/GetMyClassesClassResult";
import { GetMyClassesClassStudentRepliesResult } from "results/users/my-classes/GetMyClassesClassStudentRepliesResult";
import { GetMyClassesClassStudentResult } from "results/users/my-classes/GetMyClassesClassStudentResult";
import { GetMyClassesResult } from "results/users/my-classes/GetMyClassesResult";
import { useApiService } from "services/ApiService";

export const UsersApiService = () => {
    const { get, post } = useApiService({ controller: "/users" });

    return {
        getProfile: () => get<ProfileResult>("/profile"),
        getReferAFriend: () => get<GetProfileReferAFriendResult>("/refer-a-friend"),
        generateReferrerCode: () => post<CodeResult>("/generate-referrer-code"),
        getProfileMyAvatars: () => get<GetProfileMyAvatarsResult>("/my-avatars"),
        getUnlockedAvatars: () => get<GetUnlockedAvatarsResult>("/unlocked-avatars"),
        selectProfileAvatar: (avatarId?: number) => post("/select-avatar", { avatarId }),
        getMyClasses: () => get<GetMyClassesResult>("/my-classes"),
        getMyClassesClass: (id: number) => get<GetMyClassesClassResult>(`/my-classes/${id}`),
        getMyClassesClassStudent: (classId: number, studentId: number) =>
            get<GetMyClassesClassStudentResult>(`/my-classes/${classId}/${studentId}`),
        getMyClassesClassStudentReplies: (classId: number, studentId: number) =>
            get<GetMyClassesClassStudentRepliesResult>(`/my-classes/${classId}/${studentId}/replies`),
        getMyClassesClassGames: (classId: number) => get<GetMyClassesClassGamesResult>(`/my-classes/${classId}/games`),
        joinGroup: (command: CodeCommand) => post<IdResult>("/join-group", command),
        getMySchool: () => get<GetProfileMySchoolResult>("/my-school"),
    };
};
